import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { VIEW_STATES, ADD_STATE } from "../actions";
import {
  fetchStatesSuccessfull,
  addNewStateSuucessfull,
  addNewStateError,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function* watchLoadStates() {
  yield takeEvery(VIEW_STATES, loadStates);
  // yield call(loadStates);
}

const getStates = async (country) => {
  try {
    const response = await axios.get(`${API_URL}locations/states/${country}`, {
      AuthToken,
    });

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadStates({ payload }) {
  console.log(payload);
  const { country } = payload;
  try {
    const states = yield call(getStates, country);
    if (states) {
      yield put(fetchStatesSuccessfull(states));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    // return error;
    console.log(error);
  }
}

/****************add state *****************/
export function* watchAddState() {
  yield takeEvery(ADD_STATE, addNewstateFunc);
}

const newState = async (country, state) => {
  console.log(country, state);
  try {
    const response = await axios.post(
      `${API_URL}locations/state/add/${country}`,
      // { AuthToken },
      {
        country,
        state,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewstateFunc({ payload }) {
  const { country, state } = payload.newState;
  try {
    const addCityResponse = yield call(newState, country, state);

    if (addCityResponse.response) {
      if (addCityResponse.response.data.type === 2) {
        yield put(addNewStateError(addCityResponse.response.data.message));
        toast.error(addCityResponse.response.data.message);
      } else if (addCityResponse.response.data.type === 1) {
        yield put(addNewStateError(addCityResponse.response.data.message));
        toast.error(addCityResponse.response.data.message);
      } else {
        yield put(
          addNewStateError(addCityResponse.response.data.errors[0].message)
        );
        toast.error(addCityResponse.response.data.errors[0].message);
      }
    } else {
      yield put(addNewStateSuucessfull(addCityResponse.data.data.description));
      toast.success(addCityResponse.data.data.description);
    }
  } catch (error) {
    yield put(addNewStateError(error));
  }
}

// export function* watchLoadStates() {
// 	yield take(VIEW_STATES);
// 	yield call(loadStates);
// }

export default function* rootSaga() {
  yield all([fork(watchLoadStates), fork(watchAddState)]);
}
