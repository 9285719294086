import {
  VIEW_STATES,
  VIEW_STATES_SUCCESSFULL,
  ADD_STATE,
  ADD_STATE_SUUCESSFULL,
  ADD_STATE_FAIL,
} from "../actions";

const INIT_STATE = {
  states: [],
  country: {},
  error: "",
  state_add_message: "",
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VIEW_STATES:
      return {
        ...state,
        country: action,
      };
    case VIEW_STATES_SUCCESSFULL:
      console.log("viewstatesuccess", action);
      return {
        ...state,
        states: action.payload.states,
      };
    case ADD_STATE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_STATE_SUUCESSFULL:
      return {
        ...state,
        state_add_message: "",
        error: "",
        loading: false,
      };
    case ADD_STATE_FAIL:
      return {
        ...state,
        error: "",
        state_add_message: "",
        loading: false,
      };
    default:
      return state;
  }
};

export default state;
