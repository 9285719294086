import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_PROJECT_LIST,
  FETCH_PROJECT_3D_LIST,
  ADD_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_COST,
  UPDATE_PROJECT_COMMISSION,
  FETCH_PROJECT_LIST_DETAILS,
} from "../actions";
import {
  fetchProjectList,
  fetch3dProjectList,
  addProjectSuccess,
  addProjectFail,
  updateProjectCostSuccess,
  updateProjectCostFail,
  fetchProjectDetailsSuccess,
  fetchProjectDetailsFail,
  updateProjectSuccess,
  updateProjectFail,
  updateProjectCommissionSuccess,
  updateProjectCommissionFail,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function* watchLoadProjectList() {
  while (true) {
    yield take(FETCH_PROJECT_LIST);
    yield call(loadProjectList);
  }
}

export function* watchLoadthreeDProjectList() {
  while (true) {
    yield take(FETCH_PROJECT_3D_LIST);
    yield call(loadThreeDProjectList);
  }
  //   yield takeEvery(FETCH_PROJECT_3D_LIST, loadThreeDProjectList);
}

const getProjectList = async () => {
  try {
    const response = await axios.get(`${API_URL}projects/types`, {
      AuthToken,
    });
    console.log("projectlistreponse", response);

    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadProjectList() {
  try {
    const projectList = yield call(getProjectList);
    console.log("projectlist", projectList);
    if (projectList) {
      yield put(fetchProjectList(projectList));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    // return error;
    console.log(error);
  }
}

/************3d project list *************/
const getThreeDProjectList = async () => {
  try {
    const response = await axios.get(`${API_URL}projects/count`, {
      AuthToken,
    });
    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadThreeDProjectList() {
  try {
    const threeDprojectList = yield call(getThreeDProjectList);
    console.log("3dprojhect", threeDprojectList);
    if (threeDprojectList) {
      yield put(fetch3dProjectList(threeDprojectList));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    // return error;
    console.log(error);
  }
}

/*********************ADD PROJECT  *****************/
export function* watchAddProject() {
  yield takeEvery(ADD_PROJECT, addNewProjectFunc);
}

const newProject = async (
  name,
  type,
  city,
  state,
  country,
  pincode,
  discussion_start_time,
  discussion_end_time,
  description
) => {
  try {
    const response = await axios.post(
      `${API_URL}projects/add`,

      {
        name,
        type,
        city,
        state,
        country,
        pincode,
        discussion_start_time,
        discussion_end_time,
        description,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewProjectFunc({ payload }) {
  console.log("productpayload", payload);
  const {
    name,
    type,
    city,
    state,
    country,
    pincode,
    discussion_start_time,
    discussion_end_time,
    description,
  } = payload.projectValue;
  try {
    const addProjectResponse = yield call(
      newProject,
      name,
      type,
      city,
      state,
      country,
      pincode,
      discussion_start_time,
      discussion_end_time,
      description
    );

    console.log(
      "addprojectreponse",
      addProjectResponse,
      addProjectResponse.response
    );

    if (addProjectResponse.response) {
      if (addProjectResponse.response.data.type === 2) {
        yield put(addProjectFail(addProjectResponse.response.data.message));
        toast.error(addProjectResponse.response.data.message);
      } else if (addProjectResponse.response.data.type === 1) {
        yield put(addProjectFail(addProjectResponse.response.data.message));
        toast.error(addProjectResponse.response.data.message);
      } else if (addProjectResponse.response.data.type === 3) {
        yield put(addProjectFail(addProjectResponse.response.data.message));
        toast.error(addProjectResponse.response.data.message);
      } else {
        yield put(
          addProjectFail(addProjectResponse.response.data.errors[0].message)
        );
        toast.error(addProjectResponse.response.data.errors[0].message);
      }
    } else {
      yield put(addProjectSuccess(addProjectResponse.data.data.description));
      toast.success(addProjectResponse.data.data.description);
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

/******************project details *****************/
export function* watchLoadProjectDetail() {
  yield takeEvery(FETCH_PROJECT_LIST_DETAILS, projectDetailFunc);
}

const projectDetails = async (id) => {
  console.log("id", id);
  try {
    const response = await axios.get(`${API_URL}projects/details/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

function* projectDetailFunc({ payload }) {
  try {
    const addProjectResponse = yield call(projectDetails, payload.projectID);
    if (addProjectResponse.data.data.items) {
      yield put(fetchProjectDetailsSuccess(addProjectResponse.data.data.items));
    } else {
      toast.error("Error while fetching project details");
    }
  } catch (error) {
    yield put(fetchProjectDetailsFail(error));
  }
}

/*********************UPDATE PROJECT *********************/
export function* watchUpdtaeProject() {
  yield takeEvery(UPDATE_PROJECT, updateProjectFunc);
}

const updateProject = async (
  type,
  status,
  remarks,
  discussion_start_time,
  discussion_end_time,
  tentative_project_end_time,
  briefing_status,
  output_download_link,
  projectID
) => {
  try {
    const response = await axios.put(
      `${API_URL}projects/details/${projectID}`,

      {
        type,
        status,
        remarks,
        discussion_start_time,
        discussion_end_time,
        tentative_project_end_time,
        briefing_status,
        output_download_link,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* updateProjectFunc({ payload }) {
  const {
    type,
    status,
    remarks,
    discussion_start_time,
    discussion_end_time,
    tentative_project_end_time,
    briefing_status,
    output_download_link,
  } = payload.updateProjectValue;
  const { projectID } = payload;
  console.log("peops", payload);
  try {
    const updateProjectResponse = yield call(
      updateProject,
      type,
      status,
      remarks,
      discussion_start_time,
      discussion_end_time,
      tentative_project_end_time,
      briefing_status,
      output_download_link,
      projectID
    );

    if (updateProjectResponse.response) {
      if (updateProjectResponse.response.data.type === 2) {
        yield put(
          updateProjectFail(updateProjectResponse.response.data.message)
        );
        toast.error(updateProjectResponse.response.data.message);
      } else if (updateProjectResponse.response.data.type === 1) {
        yield put(
          updateProjectFail(updateProjectResponse.response.data.message)
        );
        toast.error(updateProjectResponse.response.data.message);
      } else {
        yield put(
          updateProjectFail(
            updateProjectResponse.response.data.errors[0].message
          )
        );
        toast.error(updateProjectResponse.response.data.errors[0].message);
      }
    } else {
      yield put(
        updateProjectSuccess(updateProjectResponse.data.data.description)
      );
      toast.success(updateProjectResponse.data.data.description);
      payload.closeEditModalHandler(false);
    }
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

/******************UPDATE COST ******************/
export function* watchUpdtaeProjectCost() {
  yield takeEvery(UPDATE_PROJECT_COST, updateProjectCostFunc);
}

const updateProjectCost = async (cost, discount, id) => {
  try {
    const response = await axios.put(
      `${API_URL}projects/cost/${id}`,

      {
        cost,
        discount,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* updateProjectCostFunc({ payload }) {
  const { cost, discount, id } = payload.projectCostValue;
  try {
    const addProjectResponse = yield call(
      updateProjectCost,
      cost,
      discount,
      id
    );

    if (addProjectResponse.response) {
      if (addProjectResponse.response.data.type === 2) {
        yield put(
          updateProjectCostFail(addProjectResponse.response.data.message)
        );
        toast.error(addProjectResponse.response.data.message);
      } else if (addProjectResponse.response.data.type === 1) {
        yield put(
          updateProjectCostFail(addProjectResponse.response.data.message)
        );
        toast.error(addProjectResponse.response.data.message);
      } else {
        yield put(
          updateProjectCostFail(
            addProjectResponse.response.data.errors[0].message
          )
        );
        toast.error(addProjectResponse.response.data.errors[0].message);
      }
    } else {
      yield put(
        updateProjectCostSuccess(addProjectResponse.data.data.description)
      );
      toast.success(addProjectResponse.data.data.description);
      payload.closeCostModalHandler(false);
    }
  } catch (error) {
    yield put(updateProjectCostFail(error));
  }
}

/*****************update project commission ***************/
export function* watchUpdtaeProjectCommission() {
  yield takeEvery(UPDATE_PROJECT_COMMISSION, updateProjectCommissionFunc);
}

const updateProjectCommission = async (
  super_distributor_commission,
  distributor_commission,
  id
) => {
  try {
    const response = await axios.put(
      `${API_URL}projects/commissions/${id}`,

      {
        super_distributor_commission,
        distributor_commission,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* updateProjectCommissionFunc({ payload }) {
  const { super_distributor_commission, distributor_commission, id } =
    payload.projectCommissionValue;
  try {
    const addProjectResponse = yield call(
      updateProjectCommission,
      super_distributor_commission,
      distributor_commission,
      id
    );

    if (addProjectResponse.response) {
      if (addProjectResponse.response.data.type === 2) {
        yield put(
          updateProjectCostFail(addProjectResponse.response.data.message)
        );
        toast.error(addProjectResponse.response.data.message);
      } else if (addProjectResponse.response.data.type === 1) {
        yield put(
          updateProjectCostFail(addProjectResponse.response.data.message)
        );
        toast.error(addProjectResponse.response.data.message);
      } else {
        yield put(
          updateProjectCostFail(
            addProjectResponse.response.data.errors[0].message
          )
        );
        toast.error(addProjectResponse.response.data.errors[0].message);
      }
    } else {
      yield put(
        updateProjectCostSuccess(addProjectResponse.data.data.description)
      );
      toast.success(addProjectResponse.data.data.description);
      payload.closeCostModalHandler(false);
    }
  } catch (error) {
    yield put(updateProjectCostFail(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadProjectList),
    fork(watchLoadthreeDProjectList),
    fork(watchAddProject),
    fork(watchLoadProjectDetail),
    fork(watchUpdtaeProject),
    fork(watchUpdtaeProjectCost),
    fork(watchUpdtaeProjectCommission),
  ]);
}
