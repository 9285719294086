import {
  VIEW_STATES,
  VIEW_STATES_SUCCESSFULL,
  ADD_STATE,
  ADD_STATE_SUUCESSFULL,
  ADD_STATE_FAIL,
} from "../actions";

export const fetchStates = (country) => ({
  type: VIEW_STATES,
  payload: { country },
});

export const fetchStatesSuccessfull = (states) => ({
  type: VIEW_STATES_SUCCESSFULL,
  payload: { states },
});

export const addNewState = (newState, history) => ({
  type: ADD_STATE,
  payload: { newState, history },
});

export const addNewStateSuucessfull = (message) => ({
  type: ADD_STATE_SUUCESSFULL,
  payload: { message },
});

export const addNewStateError = (message) => ({
  type: ADD_STATE_FAIL,
  payload: { message },
});
