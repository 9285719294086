import {
  FETCH_PROJECT_LIST,
  FETCH_PROJECT_3D_LIST,
  ADD_PROJECT,
  ADD_PROJECT_FAIL,
  ADD_PROJECT_SUCCESS,
  FETCH_PROJECT_LIST_DETAILS,
  FETCH_PROJECT_LIST_DETAILS_FAIL,
  FETCH_PROJECT_LIST_DETAILS_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_COST,
  UPDATE_PROJECT_COST_FAIL,
  UPDATE_PROJECT_COST_SUCCESS,
  UPDATE_PROJECT_COMMISSION,
  UPDATE_PROJECT_COMMISSION_FAIL,
  UPDATE_PROJECT_COMMISSION_SUCCESS,
} from "../actions";

export const fetchProjectList = (projectList) => ({
  type: FETCH_PROJECT_LIST,
  projectList,
});

export const fetch3dProjectList = (threeDprojectList) => ({
  type: FETCH_PROJECT_3D_LIST,
  threeDprojectList,
});

export const addProject = (projectValue) => ({
  type: ADD_PROJECT,
  payload: { projectValue },
});

export const addProjectSuccess = (message) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: { message },
});

export const addProjectFail = (message) => ({
  type: ADD_PROJECT_FAIL,
  payload: { message },
});

export const fetchProjectDetails = (projectID) => ({
  type: FETCH_PROJECT_LIST_DETAILS,
  payload: { projectID },
});

export const fetchProjectDetailsSuccess = (projectDetails) => ({
  type: FETCH_PROJECT_LIST_DETAILS_SUCCESS,
  payload: { projectDetails },
});

export const fetchProjectDetailsFail = (message) => ({
  type: FETCH_PROJECT_LIST_DETAILS_FAIL,
  payload: { message },
});

export const updateProject = (
  updateProjectValue,
  closeEditModalHandler,
  projectID
) => ({
  type: UPDATE_PROJECT,
  payload: { updateProjectValue, closeEditModalHandler, projectID },
});

export const updateProjectSuccess = (message) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: { message },
});

export const updateProjectFail = (message) => ({
  type: UPDATE_PROJECT_FAIL,
  payload: { message },
});

export const updateProjectCost = (projectCostValue, closeCostModalHandler) => ({
  type: UPDATE_PROJECT_COST,
  payload: { projectCostValue, closeCostModalHandler },
});

export const updateProjectCostSuccess = (message) => ({
  type: UPDATE_PROJECT_COST_SUCCESS,
  payload: { message },
});

export const updateProjectCostFail = (message) => ({
  type: UPDATE_PROJECT_COST_FAIL,
  payload: { message },
});

export const updateProjectCommission = (
  projectCommissionValue,
  closeCostModalHandler
) => ({
  type: UPDATE_PROJECT_COMMISSION,
  payload: { projectCommissionValue, closeCostModalHandler },
});

export const updateProjectCommissionSuccess = (message) => ({
  type: UPDATE_PROJECT_COMMISSION_SUCCESS,
  payload: { message },
});

export const updateProjectCommissionFail = (message) => ({
  type: UPDATE_PROJECT_COMMISSION_FAIL,
  payload: { message },
});
