/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* DISTRIBUTER */
export const FETCH_AEPS_COMMISION = "FETCH_AEPS_COMMISION";
export const FETCH_DMT_COMMISION = "FETCH_DMT_COMMISION";
export const FETCH_DTH_COMMISION = "FETCH_DTH_COMMISION";
export const FETCH_ELECTRICITY_COMMISION = "FETCH_ELECTRICITY_COMMISION";
export const FETCH_MOBILE_POSTPAID_COMMISION =
  "FETCH_MOBILE_POSTPAID_COMMISION";
export const FETCH_MOBILE_PREPAID_COMMISION = "FETCH_MOBILE_PREPAID_COMMISION";
export const FETCH_PAN_COMMISION = "FETCH_PAN_COMMISION";
export const FETCH_FILTER_DISTRIBTUOR = "FETCH_FILTER_DISTRIBTUOR";
export const FETCH_FILTER_DISTRIBTUOR_FAIL = "FETCH_FILTER_DISTRIBTUOR_FAIL";
export const FETCH_FILTER_DISTRIBTUOR_SUCCESS =
  "FETCH_FILTER_DISTRIBTUOR_SUCCESS";
export const FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR =
  "FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR";
export const FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_FAIL =
  "FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_FAIL";
export const FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_SUCCESS =
  "FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_SUCCESS";
export const FETCH_RETAILER_UNDER_DISTRIBUTOR =
  "FETCH_RETAILER_UNDER_DISTRIBUTOR";
export const FETCH_RETAILER_UNDER_DISTRIBUTOR_FAIL =
  "FETCH_RETAILER_UNDER_DISTRIBUTOR_FAIL";
export const FETCH_RETAILER_UNDER_DISTRIBUTOR_SUCCESS =
  "FETCH_RETAILER_UNDER_DISTRIBUTOR_SUCCESS";
export const ADD_DISTRIBUTERS = "ADD_DISTRIBUTERS";
export const ADD_DISTRIBUTERS_FAIL = "ADD_DISTRIBUTERS_FAIL";
export const ADD_DISTRIBUTERS_SUCCESS = "ADD_DISTRIBUTERS_SUCCESS";
export const FETCH_DISTRIBUTORS_LIST = "FETCH_DISTRIBUTORS_LIST";
export const FETCH_DISTRIBUTORS_LIST_FAIL = "FETCH_DISTRIBUTORS_LIST_FAIL";
export const FETCH_DISTRIBUTORS_LIST_SUCCESS =
  "FETCH_DISTRIBUTORS_LIST_SUCCESS";
export const VIEW_DISTRIBUTOR_DETAIL = "VIEW_DISTRIBUTOR_DETAIL";
export const MODIFY_DISTRIBUTOR = "MODIFY_DISTRIBUTOR";
export const MODIFY_DISTRIBUTOR_FAIL = "MODIFY_DISTRIBUOT_FAIL";
export const MODIFY_DISTRIBUTOR_SUCCESS = "MODIFY_DISTRIBUTOR_SUCCESS";
export const UPLOAD_PROFILE_PIC_DISTRIBUTOR = "UPLOAD_PROFILE_PIC_DISTRIBUTOR";
export const UPLOAD_PROFILE_PIC_DISTRIBUTOR_FAIL =
  "UPLOAD_PROFILE_PIC_DISTRIBUTOR_FAIL";
export const UPLOAD_PROFILE_PIC_DISTRIBUTOR_SUCCESS =
  "UPLOAD_PROFILE_PIC_DISTRIBUTOR_SUCCESS";

/* EMPLOYEE */
export const FETCH_EMPLOYEE_LIST = "FETCH_EMPLOYEE_LIST";
export const FETCH_EMPLOYEE_LIST_FAIL = "FETCH_EMPLOYEE_LIST_FAIL";
export const FETCH_EMPLOYEE_LIST_SUCCESS = "FETCH_EMPLOYEE_LIST_SUCCESS";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";
export const VIEW_EMPLOYEE_DETAIL = "VIEW_EMPLOYEE_DETAIL";
export const MODIFY_EMPLOYEE = "MODIFY_EMPLOYEE";
export const MODIFY_EMPLOYEE_FAIL = "MODIFY_EMPLOYEE_FAIL";
export const MODIFY_EMPLOYEE_SUCCESS = "MODIFY_EMPLOYEE_SUCCESS";
export const FETCH_PROFILE_PIC_EMPLOYEE = "FETCH_PROFILE_PIC_EMPLOYEE";
export const UPLOAD_PROFILE_PIC_EMPLOYEE = "UPLOAD_PROFILE_PIC_EMPLOYEE";
export const UPLOAD_PROFILE_PIC_EMPLOYEE_FAIL =
  "UPLOAD_PROFILE_PIC_EMPLOYEE_FAIL";
export const UPLOAD_PROFILE_PIC_EMPLOYEE_SUCCESS =
  "UPLOAD_PROFILE_PIC_EMPLOYEE_SUCCESS";
export const FETCH_EMPLOYEE_PAYMENT_LIST = "FETCH_EMPLOYEE_PAYMENT_LIST";
export const FETCH_EMPLOYEE_PAYMENT_LIST_FAIL =
  "FETCH_EMPLOYEE_PAYMENT_LIST_FAIL";
export const FETCH_EMPLOYEE_PAYMENT_LIST_SUCCESS =
  "FETCH_EMPLOYEE_PAYMENT_LIST_SUCCESS";
export const FETCH_EMPLOYEE_DISPUTE_LIST = "FETCH_EMPLOYEE_DISPUTE_LIST";
export const FETCH_EMPLOYEE_DISPUTE_LIST_FAIL =
  "FETCH_EMPLOYEE_DISPUTE_LIST_FAIL";
export const FETCH_EMPLOYEE_DISPUTE_LIST_SUCCESS =
  "FETCH_EMPLOYEE_DISPUTE_LIST_SUCCESS";
export const ADD_EMPLOYEE_DISPUTE_COMMENT = "ADD_EMPLOYEE_DISPUTE_COMMENT";
export const ADD_EMPLOYEE__DISPUTE__COMMENT_FAIL =
  "ADD_EMPLOYEE__DISPUTE__COMMENT_FAIL";
export const ADD_EMPLOYEE__DISPUTE__COMMENT_SUCCESS =
  "ADD_EMPLOYEE__DISPUTE__COMMENT_SUCCESS";
export const FETCH_EMPLOYEE_DISPUTE_DETAIL = "FETCH_EMPLOYEE_DISPUTE_DETAIL";
export const FETCH_EMPLOYEE_DISPUTE_DETAIL_FAIL =
  "FETCH_EMPLOYEE_DISPUTE_DETAIL_FAIL";
export const FETCH_EMPLOYEE_DISPUTE_DETAIL_SUCCESS =
  "FETCH_EMPLOYEE_DISPUTE_DETAIL_SUCCESS";

/*	 RETAILER*/
export const FETCH_AEPS_COMMISION_RETAILER = "FETCH_AEPS_COMMISION_RETAILER";
export const FETCH_DMT_COMMISION_RETAILER = "FETCH_DMT_COMMISION_RETAILER";
export const FETCH_DTH_COMMISION_RETAILER = "FETCH_DTH_COMMISION_RETAILER";
export const FETCH_ELECTRICITY_COMMISION_RETAILER =
  "FETCH_ELECTRICITY_COMMISION_RETAILER";
export const FETCH_MOBILE_POSTPAID_COMMISION_RETAILER =
  "FETCH_MOBILE_POSTPAID_COMMISION_RETAILER";
export const FETCH_MOBILE_PREPAID_COMMISION_RETAILER =
  "FETCH_MOBILE_PREPAID_COMMISION_RETAILER";
export const FETCH_PAN_COMMISION_RETAILER = "FETCH_PAN_COMMISION_RETAILER";
export const FETCH_RETAILER_NOTIFICATION = "FETCH_RETAILER_NOTIFICATION";
export const ADD_RETAILER = "ADD_RETAILER";
export const ADD_RETAILER_FAIL = "ADD_RETAILER_FAIL";
export const ADD_RETAILER_SUCCESS = "ADD_RETAILER_SUCCESS";
export const FETCH_RETAILERS_LIST = "FETCH_RETAILERS_LIST";
export const FETCH_RETAILERS_LIST_SUCCESS = "FETCH_RETAILERS_LIST_SUCCESS";
export const FETCH_RETAILERS_LIST_FAIL = "FETCH_RETAILERS_LIST_FAIL";
export const VIEW_RETAILERS_OF_DISTRIBUTORS = "VIEW_RETAILERS_OF_DISTRIBUTORS";
export const FETCH_RETAILERS_WALLET = "FETCH_RETAILERS_WALLET";
export const FETCH_RETAILER_PROFILE_IMAGE = "FETCH_RETAILER_PROFILE_IMAGE";
export const FETCH_RETAILER_PAYMENT_LIST = "FETCH_RETAILER_PAYMENT_LIST";
export const FETCH_RETAILER_PAYMENT_LIST_FAIL =
  "FETCH_RETAILER_PAYMENT_LIST_FAIL";
export const FETCH_RETAILER_PAYMENT_LIST_SUCCESS =
  "FETCH_RETAILER_PAYMENT_LIST_SUCCESS";
export const RETAILER_ADD_PAYMENT = "RETAILER_ADD_PAYMENT";
export const RETAILER_ADD_PAYMENT_FAIL = "RETAILER_ADD_PAYMENT_FAIL";
export const RETAILER_ADD_PAYMENT_SUCCESS = "RETAILER_ADD_PAYMENT_SUCCESS";
export const FETCH_RETAILER_DISPUTE_LIST = "FETCH_RETAILER_DISPUTE_LIST";
export const FETCH_RETAILER_DISPUTE_LIST_FAIL =
  "FETCH_RETAILER_DISPUTE_LIST_FAIL";
export const FETCH_RETAILER_DISPUTE_LIST_SUCCESS =
  "FETCH_RETAILER_DISPUTE_LIST_SUCCESS";

export const FETCH_RETAILER_DISPUTE_DETAIL = "FETCH_RETAILER_DISPUTE_DETAIL";
export const FETCH_RETAILER_DISPUTE_DETAIL_FAIL =
  "FETCH_RETAILER_DISPUTE_DETAIL_FAIL";
export const FETCH_RETAILER_DISPUTE_DETAIL_SUCCESS =
  "FETCH_RETAILER_DISPUTE_DETAIL_SUCCESS";
export const ADD_RETAILER_DISPUTE = "ADD_RETAILER_DISPUTE";
export const ADD_RETAILER__DISPUTE_FAIL = "ADD_RETAILER__DISPUTE_FAIL";
export const ADD_RETAILER__DISPUTE_SUCCESS = "ADD_RETAILER__DISPUTE_SUCCESS";
export const ADD_RETAILER_DISPUTE_COMMENT = "ADD_RETAILER_DISPUTE_COMMENT";
export const ADD_RETAILER__DISPUTE__COMMENT_FAIL =
  "ADD_RETAILER__DISPUTE__COMMENT_FAIL";
export const ADD_RETAILER__DISPUTE__COMMENT_SUCCESS =
  "ADD_RETAILER__DISPUTE__COMMENT_SUCCESS";

export const RETAILER_WITHDRAWAL = "RETAILER_WITHDRAWAL";
export const RETAILER_WITHDRAWAL_SUCCESS = "RETAILER_WITHDRAWAL_SUCCESS";
export const RETAILER_WITHDRAWAL_FAIL = "RETAILER_WITHDRAWAL_FAIL";
export const RETAILER_BALANCE = "RETAILER_BALANCE";
export const RETAILER_BALANCE_SUCCESS = "RETAILER_BALANCE_SUCCESS";
export const RETAILER_BALANCE_FAIL = "RETAILER_BALANCE_FAIL";

/*AMDIN*/
export const FETCH_AEPS_COMMISION_ADMIN = "FETCH_AEPS_COMMISION_ADMIN";
export const UPDATE_ADMIN_AEPS_COMMISSION = "UPDATE_ADMIN_AEPS_COMMISSION";
export const UPDATE_ADMIN_AEPS_COMMISSION_FAIL =
  "UPDATE_ADMIN_AEPS_COMMISSION_FAIL";
export const UPDATE_ADMIN_AEPS_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_AEPS_COMMISSION_SUCCESS";
export const FETCH_DMT_COMMISION_ADMIN = "FETCH_DMT_COMMISION_ADMIN";
export const UPDATE_ADMIN_DMT_COMMISSION = "UPDATE_ADMIN_DMT_COMMISSION";
export const UPDATE_ADMIN_DMT_COMMISSION_FAIL =
  "UPDATE_ADMIN_DMT_COMMISSION_FAIL";
export const UPDATE_ADMIN_DMT_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_DMT_COMMISSION_SUCCESS";
export const FETCH_DTH_COMMISION_ADMIN = "FETCH_DTH_COMMISION_ADMIN";
export const UPDATE_ADMIN_DTH_COMMISSION = "UPDATE_ADMIN_DTH_COMMISSION";
export const UPDATE_ADMIN_DTH_COMMISSION_FAIL =
  "UPDATE_ADMIN_DTH_COMMISSION_FAIL";
export const UPDATE_ADMIN_DTH_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_DTH_COMMISSION_SUCCESS";
export const FETCH_ELECTRICITY_COMMISION_ADMIN =
  "FETCH_ELECTRICITY_COMMISION_ADMIN";
export const UPDATE_ADMIN_ELECTRICITY_COMMISSION =
  "UPDATE_ADMIN_ELECTRICITY_COMMISSION";
export const UPDATE_ADMIN_ELECTRICITY_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_ELECTRICITY_COMMISSION_SUCCESS";
export const UPDATE_ADMIN_ELECTRICITY_COMMISSION_FAIL =
  "UPDATE_ADMIN_ELECTRICITY_COMMISSION_FAIL";
export const FETCH_MOBILE_POSTPAID_COMMISION_ADMIN =
  "FETCH_MOBILE_POSTPAID_COMMISION_ADMIN";
export const UPDATE_ADMIN_POSTPAID_COMMISSION =
  "UPDATE_ADMIN_POSTPAID_COMMISSION";
export const UPDATE_ADMIN_POSTPAID_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_POSTPAID_COMMISSION_SUCCESS";
export const UPDATE_ADMIN_POSTPAID_COMMISSION_FAIL =
  "UPDATE_ADMIN_POSTPAID_COMMISSION_FAIL";
export const FETCH_MOBILE_PREPAID_COMMISION_ADMIN =
  "FETCH_MOBILE_PREPAID_COMMISION_ADMIN";
export const UPDATE_ADMIN_PREPAID_COMMISSION =
  "UPDATE_ADMIN_PREPAID_COMMISSION";
export const UPDATE_ADMIN_PREPAID_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_PREPAID_COMMISSION_SUCCESS";
export const UPDATE_ADMIN_PREPAID_COMMISSION_FAIL =
  "UPDATE_ADMIN_PREPAID_COMMISSION_FAIL";
export const FETCH_PAN_COMMISION_ADMIN = "FETCH_PAN_COMMISION_ADMIN";
export const UPDATE_ADMIN_PAN_COMMISSION = "UPDATE_ADMIN_PAN_COMMISSION";
export const UPDATE_ADMIN_PAN_COMMISSION_SUCCESS =
  "UPDATE_ADMIN_PAN_COMMISSION_SUCCESS";
export const UPDATE_ADMIN_PAN_COMMISSION_FAIL =
  "UPDATE_ADMIN_PAN_COMMISSION_FAIL";
/*STATE*/
export const VIEW_STATES = "VIEW_STATES";
export const VIEW_STATES_SUCCESSFULL = "VIEW_STATES_SUCCESSFULL";
/* CITY */
export const ADD_CITY = "ADD_CITY";
export const ADD_CITY_SUUCESSFULL = "ADD_CITY_SUUCESSFULL";
export const FETCH_CITY = "FETCH_CITY";
export const ADD_STATE = "ADD_STATE";
export const ADD_STATE_SUUCESSFULL = "ADD_STATE_SUUCESSFULL";
export const ADD_STATE_FAIL = "ADD_STATE_FAIL";

export const FETCH_CITY_SUCCESSFULL = "FETCH_CITY_SUCCESSFULL";
export const FETCH_CITY_FAIL = "FETCH_CITY_FAIL";
export const CITY_ERROR = "CITY_ERROR";
export const CITY_PARENT_ERROR = "CITY_PARENT_ERROR";
export const MODIFY_CITY = "MODIFY_CITY";
export const MODIFY_CITY_SUCCESSFULL = "MODIFY_CITY_SUCCESSFULL";
export const MODIFY_CITY_FAIL = "MODIFY_CITY_FAIL";

/*COUNTRY */
export const FETCH_COUNTRY = "FETCH_COUNTRY";

/*DOWNLOAD*/
export const ADD_DOWNLOAD_LINK = "ADD_DOWNLOAD_LINK";
export const ADD_DOWNLOAD_LINK_FAIL = "ADD_DOWNLOAD_LINK_FAIL";
export const ADD_DOWNLOAD_LINK_SUCCESS = "ADD_DOWNLOAD_LINK_SUCCESS";
export const FETCH_DOWNLOAD_LINK = "FETCH_DOWNLOAD_LINK";
export const FETCH_DOWNLOAD_LINK_FAIL = "FETCH_DOWNLOAD_LINK_FAIL";
export const FETCH_FILTER_DOWNLOAD_LINK = "FETCH_FILTER_DOWNLOAD_LINK";
export const FETCH_FILTER_DOWNLOAD_LINK_FAIL =
  "FETCH_FILTER_DOWNLOAD_LINK_FAIL";
export const FETCH_FILTER_DOWNLOAD_LINK_SUCCESS =
  "FETCH_FILTER_DOWNLOAD_LINK_SUCCESS";
export const FETCH_DOWNLOAD_LINK_SUCCESS = "FETCH_DOWNLOAD_LINK_SUCCESS";
export const MODIFY_DOWNLOAD_LINK = "MODIFY_DOWNLOAD_LINK";
export const MODIFY_DOWNLOAD_LINK_FAIL = "MODIFY_DOWNLOAD_LINK_FAIL";
export const MODIFY_DOWNLOAD_LINK_SUCCESS = "MODIFY_DOWNLOAD_LINK_SUCCESS";

/*DRIVER DOWNLOAD*/
export const FETCH_DRIVER = "FETCH_DRIVER";
export const FETCH_DRIVER_SUCCESS = "FETCH_DRIVER_SUCCESS";
/*DESKTOPTOOLs DOWNLOAD*/
export const FETCH_DESKTOPTOOLS = "FETCH_DESKTOPTOOLS";
export const FETCH_DESKTOPTOOLS_SUCCESS = "FETCH_RETAILERS_LIST_SUCCESS";

/*PROJECT */
export const FETCH_PROJECT_LIST = "FETCH_PROJECT_LIST";
export const FETCH_PROJECT_LIST_DETAILS = "FETCH_PROJECT_LIST_DETAILS";
export const FETCH_PROJECT_LIST_DETAILS_FAIL =
  "FETCH_PROJECT_LIST_DETAILS_FAIL";
export const FETCH_PROJECT_LIST_DETAILS_SUCCESS =
  "FETCH_PROJECT_LIST_DETAILS_SUCCESS";
export const FETCH_PROJECT_3D_LIST = "FETCH_PROJECT_3D_LIST";
export const ADD_PROJECT = "ADD_PROJECT";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_COST = "UPDATE_PROJECT_COST";
export const UPDATE_PROJECT_COST_FAIL = "UPDATE_PROJECT_COST_FAIL";
export const UPDATE_PROJECT_COST_SUCCESS = "UPDATE_PROJECT_COST_SUCCESS";
export const UPDATE_PROJECT_COMMISSION = "UPDATE_PROJECT_COMMISSION";
export const UPDATE_PROJECT_COMMISSION_FAIL = "UPDATE_PROJECT_COMMISSION_FAIL";
export const UPDATE_PROJECT_COMMISSION_SUCCESS =
  "UPDATE_PROJECT_COMMISSION_SUCCESS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./distributor/actions";
export * from "./retailer/actions";
export * from "./state/actions";
export * from "./city/actions";
export * from "./country/actions";
export * from "./employee/actions";
export * from "./download/actions";
export * from "./driver/actions";
export * from "./desktoptool/actions";
export * from "./admin/actions";
export * from "./project/actions";
